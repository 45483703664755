import React, { useState } from 'react';

import Chevron from 'assets/icons/menu-chevron-icon.inline.svg';

import styles from './mobile-accordion.module.scss';

const MobileAccordion = () => {
  const [isShown, toggle] = useState(false);
  const shownListClass = [styles.list, styles['list__shown']].join(' ');
  const activeChevronClass = [styles.chevron, styles['chevron__active']].join(
    ' '
  );
  return (
    <div>
      <p className={styles.title} onClick={() => toggle(!isShown)}>
        Categories
        <Chevron className={isShown ? activeChevronClass : styles.chevron} />
      </p>
      <div className={isShown ? shownListClass : styles.list}>
        <div className={styles.linkBlock}>
          <a href="/" className={styles.link}>
            Categories
          </a>
          <a href="/" className={styles.link}>
            Projects
          </a>
          <a href="/" className={styles.link}>
            Search
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileAccordion;
