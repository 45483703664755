import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { Link } from 'gatsby';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import MobileAccordion from 'components/ui/mobile-accordion/mobile-accordion';

import CloseIcon from 'assets/icons/menu-close-icon.inline.svg';

import styles from './mobile-menu.module.scss';

const MobileMenu = ({ isShown, toggle }) => {
  const shownClass = [styles.root, styles['root__shown']].join(' ');

  const [token, setToken] = useState(
    (typeof window !== 'undefined' && localStorage.getItem('token')) ||
      (typeof window !== 'undefined' && sessionStorage.getItem('token'))
  );

  const clickHandler = () => {
    setToken('');
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  };

  const menuRef = useRef(null);
  useEffect(() => {
    isShown ? disableBodyScroll(menuRef.current) : clearAllBodyScrollLocks();
  }, [isShown]);

  const closeClickHandler = e => {
    e.preventDefault();
    enableBodyScroll(menuRef.current);
    clearAllBodyScrollLocks();
    toggle(false);
  };

  return (
    <div className={isShown ? shownClass : styles.root} ref={menuRef}>
      <div className={styles.rootWrapper}>
        <Container style={{ padding: 0 }}>
          <Logo variant="white" />
          <CloseIcon onClick={closeClickHandler} />
        </Container>
        <div className={styles.linkBlock}>
          <MobileAccordion></MobileAccordion>
          <a href="/" className={styles.link}>
            Projects
          </a>
          <a href="/" className={styles.link}>
            Search
          </a>
        </div>
        <div className={styles.buttonWrapper}>
          <div className={styles.buttonBlock}>
            {token ? (
              <Button variant="white-secondary" onClick={clickHandler}>
                Logout
              </Button>
            ) : (
              <>
                <Link to="/auth/signin" className={styles.btnLink}>
                  <Button variant="white-secondary">Sign in</Button>
                </Link>
                <Link to="/auth/signup" className={styles.btnLink}>
                  <Button variant="white-primary" style={{ marginTop: '1rem' }}>
                    Sign up
                  </Button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
MobileMenu.propTypes = {
  isShown: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default MobileMenu;
