import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import axiosRetry from 'axios-retry';

export const useNetwork = ({ retries = 0, ...request }, instanceConfig) => {
  const instance = axios.create({
    timeout: 10000,
    ...instanceConfig,
  });

  const useAxios = makeUseAxios({ axios: instance });
  axiosRetry(instance, { retries });

  return useAxios(request, { manual: true });
};
