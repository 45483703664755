import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';

import styles from './button.module.scss';

const SButton = ({
  variant,
  type,
  children,
  classNames,
  onClick,
  loading,
  ...style
}) => {
  let variantStyles;
  switch (variant) {
    case 'primary':
      variantStyles = styles.primary;
      break;
    case 'secondary':
      variantStyles = styles.secondary;
      break;
    case 'white-primary':
      variantStyles = styles.whitePrimary;
      break;
    case 'white-secondary':
      variantStyles = styles.whiteSecondary;
      break;
    case 'programming':
      variantStyles = styles.programming;
      break;
    case 'business':
      variantStyles = styles.business;
      break;
    case 'health':
      variantStyles = styles.health;
      break;
    default:
      break;
  }
  return (
    <Button
      type={type}
      onClick={onClick}
      disabled={loading}
      className={[styles.button, variantStyles, classNames].join(' ')}
      {...style}
    >
      {loading ? <Spinner animation="border" role="status" /> : children}
    </Button>
  );
};
SButton.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classNames: PropTypes.array,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default SButton;
